import { initializeConnector } from '@web3-react/core'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'

import { MAINNET_CHAINS } from '../chains'

const [mainnet, ...optionalChains] = Object.keys(MAINNET_CHAINS).map(Number)
const TomiPayBol = localStorage.getItem('IsTomipay')
// let ab = TomiPayBol ==true || TomiPayBol==null ? false : true
// console.log("TomiPayBol",typeof TomiPayBol)
// alert(TomiPayBol)
export const [walletConnectV2, hooks] = initializeConnector(
  (actions) => {
    // Log the actions here
    // alert(typeof TomiPayBol)
    // let a =TomiPayBol == 'false' ? true : false
    // console.log("actions:", TomiPayBol,a);
    // alert(a)
      return new WalletConnectV2({
        actions,
        options: {
          projectId: "56e76179389975627afbbb8fdb7d6712",
          chains: [1],
          optionalChains: [1],
          showQrModal: true
        },
        timeout: 10000,
        onError: err => {
          console.log('error in connector:', err);
        }
      });
  }
);






