let Environment = {
  nftPurchase: "0xa8E6c7AAdca4b8E87c4d061Cc7a68aA5B1BB289D",
  chinaTomi: "0xb4Af7935d3927f31b3eF9094DBCB55a55a22AaF3",
  busd: "0xdac17f958d2ee523a2206206994597c13d831ec7",
  tomi: "0x84f0bC91f23649c76139d402a1c00c6aC19A4400",
  instituson: "0x72312fAfd5f58C8D44B1769b09A0F879ce93d181",
  eth: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  usdc: "0x25762231808F040410586504fDF08Df259A2163c",
  wbtc: "0x54410d42628C7fD6720C489219033345Bcb7Cc73",
  link: "0x37bBd68526C9BDb49f18fB36983701E344f2A163",
  pepe: "0x0592EBa69Aa5f2eF68d9cb740fBaB2c0c0CC6A91",
  unisape: "0x079c46A5Ba477400a326E03Ad8D22FaBeaE57b4F",
  gems: "0xB297D4b01b286de35Ee12cdCe89Ec9488EEec0C2", //testnet
  GemsStaking: "0x034f504F34330eA87dcc8aBae2935eac9fD2eFaE", //TESTNET
  dop: "0xa048E46C35cf210bB0d5bb46b2DD06828Ef17893",
};
export default Environment;
